import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class DomainGuard implements CanActivate{
  constructor(private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const unrestrictedDomains = [
      'develop.app.payspective.com',
      'http://develop.app.payspective.com',
      'https://develop.app.payspective.com',
      'staging.app.payspective.com',
      'http://staging.app.payspective.com',
      'https://staging.app.payspective.com',
      'localhost:8100',
      'http://localhost:8100',
    ];
    const domain = window.location.host;
    if (unrestrictedDomains.includes(domain)) {
      return true;
    }

    // navigate to login page
    // eslint-disable-next-line no-underscore-dangle
    this._router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
